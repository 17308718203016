




























































import {Component, Vue, Watch} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import {namespace} from 'vuex-class';
import User from '@/models/User';
import Location from '@/models/Location';
import ColorMapperObject from '@/types/ColorMapperObject';
import CustomerArchiveComponent from '@/components/customer/CustomerArchive.component.vue';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import {TabItem} from '@/interfaces/TabItem';
import {Permission} from '@/misc/Enums/permission.enum';

const CustomerStore = namespace('customer');
const UserStore = namespace('user');
const tenantStore = namespace('tenant');
const timeScheduleStore = namespace('timeSchedule');
const JobStore = namespace('job');

@Component({
  components: {
    RJTabs,
    CustomerArchiveComponent,
    CustomerMasterComponent: () =>
        import('@/components/customer/CustomerMasterData.component.vue'),
    SearchBarComponent: () =>
        import('@/components/shared/SearchBar.component.vue'),
    ToggleButtonGroupComponent: () =>
        import('@/components/shared/ToggleButtonGroup.component.vue'),
    LocationTableComponent: () =>
        import('@/components/location/LocationTable.component.vue'),
    LocationManageMasterDataComponent: () =>
        import('@/components/location/LocationManageMasterData.component.vue'),
    JobManageComponent: () =>
        import('@/components/job/JobManage.component.vue'),
    TableComponent: () =>
        import('@/components/shared/table/Table.component.vue'),
    ActionMenuComponent: () =>
        import('@/components/shared/table/ActionMenu.component.vue'),
  },
})
export default class CustomerDashboardView extends Vue {
  //region Store Actions, Mutations & Getter
  @timeScheduleStore.Mutation('storeColors')
  public storeColors!: (colors: any) => void;
  @CustomerStore.Action('loadColor')
  public loadColorAction!: (timeScheduleId: string) => Promise<ColorMapperObject>;
  @CustomerStore.Mutation('storeLocation')
  public storeLocationMutation!: (location: Location | undefined) => void;
  @CustomerStore.Action('loadCustomerAction')
  public loadCustomerAction!: (customerId: string) => Promise<Customer>;
  @CustomerStore.Action('cancelRequestsAction')
  public cancelRequestsAction!: () => void;
  @timeScheduleStore.Action('cancelRequestsAction')
  public cancelRequestsActtAction!: () => void;
  @UserStore.Action('loadUsersAction')
  private loadUsersAction!: (payload: { tenantId: string }) => Promise<User[]>;
  @timeScheduleStore.Getter('colors')
  public colors: any;
  @JobStore.Action('cancelRequestsAction')
  public cancelJobRequestsAction!: () => void;
  public showLocationModal: boolean = false;

  /**
   * boolean to reset the overview inside html
   */
  public resetOverview: boolean = false;

  /**
   * bool to prevent showing wrong data in MasterDataComponent. Otherwise the name may be wrong
   */
  public showData: boolean = false;

  private searchString: string = '';

  private tabsModel: number = 0;

  private selectedFilter: string = 'active';
  // endregion

  @JobStore.Mutation('clearJobs')
  private clearJobs!: () => void;
  @JobStore.Mutation('clearCachedJobs')
  private clearCachedJobs!: () => void;
  @CustomerStore.Mutation('setDate')
  private setDate!: (date: string) => void;

  @CustomerStore.Getter('location')
  public _location!: Location;

  /**
   * returns the location
   */
  get location(): Location {
    return this._location;
  }

  @CustomerStore.Getter('customer')
  public _customer!: Customer;

  /**
   * returns the customer
   */
  get customer(): Customer {
    return this._customer;
  }

  public get filteredLocation(): Location[] {
    let filteredLocation: Location[] = this.customer.locations;

    switch (this.selectedFilter) {
      case 'all':
        break;
      case 'active':
        filteredLocation = filteredLocation.filter((customer) => customer.active);
        break;
      default:
        filteredLocation = filteredLocation.filter((customer) => !customer.active);
        break;
    }
    return filteredLocation;
  }

  public closeLocationManageForm() {
    this.showLocationModal = false;
  }

  public get getTabItems(): TabItem[] {
    return [
      {
        key: 'locations',
        text: this.$t('GENERAL.CLEANING_LOCATIONS').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.LOCATION_READ_OWN),
      }, {
        key: 'archive',
        text: this.$t('VERSION_CONTROL.ARCHIVE').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_READ_OWN),
      },
    ];
  }


  /**
   * Clears all Jobs and Cache from the JobStore
   */
  public clearJobsAndCache(): void {
    // Clear Jobs, so that the Calendar will reload it's data
    this.clearJobs();
    this.clearCachedJobs();
    this.cancelJobRequestsAction();
  }

  private async mounted() {
    await this.initialize();

    // Clear Jobs and Cache
    this.clearJobsAndCache();

    // Reset Saved date in state
    this.setDate('');
  }

  @Watch('location')
  private onLocationUpdate() {
    this.resetOverview = true;

    this.$nextTick(() => {
      this.resetOverview = false;
    });
  }

  @Watch('customer')
  private onCustomerUpdate() {
    this.onLocationUpdate();
  }

  /**
   * watches if the url is changing and if so, it calls the initialize
   */
  @Watch('$route', {immediate: true, deep: true})
  private onUrlChange() {
    this.initialize();
  }

  private async initialize() {
    try {
      await this.loadUsersAction({tenantId: this.$route.params.tenantId});
      await this.loadCustomerAction(this.$route.params.customerId);
      this.showData = true;
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }
}
